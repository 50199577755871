import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MainVideoColumn from "./videos/MainVideoColumn";
import PreviousVideosColumn from "./videos/PreviousVideosColumn";
import axios from "axios";
import EmptyPreviousVideosColumn from "./videos/EmptyPreviousVideosColumn";
import { useTranslation } from "react-i18next";

const SerieDiv = styled.div`
  display: flex;
  margin-top: 213px;
  margin-bottom: 110px;
  @media (max-width: 1140px) {
    flex-direction: column;
    align-items; center;
    margin-top: 160px;
    margin-bottom: 40px;
  }
  @media (max-width: 500px) {
    margin-top: 137px;
  }
`;

const Serie = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.tridentoffreedom.com/episode/latest", {
        params: {
          lang: i18n.language,
        },
      })
      .then((response) => {
        setVideos(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [i18n.language]);

  const onVideoPress = (videoId) => {
    setLoading(true);
    axios
      .get("https://api.tridentoffreedom.com/episode/" + videoId, {
        params: {
          lang: i18n.language,
        },
      })
      .then((response) => {
        setVideos(response.data);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  const otherVideos = useMemo(
    () =>
      videos && Object.values(videos) ? Object.values(videos).slice(1) : [],
    [videos]
  );

  return (
    <SerieDiv>
      <MainVideoColumn loading={loading} video={videos[0]} />
      {videos[0] && !loading && (
        <PreviousVideosColumn
          videos={otherVideos ? otherVideos : []}
          onVideoPress={onVideoPress}
        />
      )}
      {(!videos[0] || loading) && <EmptyPreviousVideosColumn />}
    </SerieDiv>
  );
};

export default Serie;
