import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { pulse } from "../../animations/pulse";

const NewestMovie = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: rgba(217, 217, 217, 1);
  animation: ${pulse} 1s infinite alternate;
`;

const MainVideo = (props) => {
  const screenWidth = window.innerWidth;
  // State to store iframe dimensions
  const [iframeDimensions, setIframeDimensions] = useState({
    width: `${screenWidth}px`,
    height: `${0.56 * screenWidth}px`,
  });

  // Function to update iframe dimensions based on screen width
  const updateIframeDimensions = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1140) {
      setIframeDimensions({
        width: "571px",
        height: "320px",
      });
    } else {
      setIframeDimensions({
        width: `${screenWidth - 40}px`,
        height: `${0.56 * screenWidth - 40}px`, // Calculate height based on screen width
      });
    }
  };

  useEffect(() => {
    // Initial size update
    updateIframeDimensions();

    // Update on window resize
    window.addEventListener("resize", updateIframeDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateIframeDimensions);
    };
  }, []); // Empty array means this effect runs once, after the initial render

  return (
    <>
      {props.loading || !props.src ? (
        <NewestMovie
          width={iframeDimensions.width}
          height={iframeDimensions.height}
        />
      ) : (
        <iframe
          width={iframeDimensions.width}
          height={iframeDimensions.height}
          src={props.src}
          frameborder="0"
          allow="fullscreen"
        ></iframe>
      )}
    </>
  );
};

export default MainVideo;
