import styled from "styled-components";

export const PrevMovieContainer = styled.button`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
  background: transparent;
  border: none;
  & > * {
    pointer-events: auto;
    cursor: pointer !important;
  }

  &:hover {
    pointer-events: auto;
    cursor: pointer !important;
    background: #f6f6f6;
  }

  &:focus {
    pointer-events: auto;
    cursor: pointer !important;
    background: #f6f6f6;
  }
`;
