import React from "react";
import styled from "styled-components";
import i18next from "i18next";

export const StyledButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;

const LanguageButton = () => {
  const currentLanguage = i18next.language;

  const onLanguageChange = () => {
    localStorage.setItem("lang", currentLanguage === "pl" ? "en" : "pl");
    i18next.changeLanguage(currentLanguage === "pl" ? "en" : "pl");
  };

  return (
    <StyledButton>
      <img
        onClick={() => onLanguageChange()}
        src={
          currentLanguage === "pl" ? "images/english.png" : "images/polish.png"
        }
      />
    </StyledButton>
  );
};

export default LanguageButton;
