import React from "react";
import PreviousVideoContainer from "./PreviousVideoContainer";
import styled from "styled-components";

const RightColumn = styled.div`
  width: 100%;
`;

const PreviousVideosColumn = (props) => {
  return (
    <RightColumn>
      {props.videos.slice(0, 4).map((video) => (
        <PreviousVideoContainer
          key={video.urlIdentifier}
          video={video}
          onVideoPress={props.onVideoPress}
        />
      ))}
    </RightColumn>
  );
};

export default PreviousVideosColumn;
