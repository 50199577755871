import React from "react";
import styled from "styled-components";
import PreviousVideo from "./PreviousVideo";
import { getFormattedDate } from "../../utils/date";
import { PrevMovieContainer } from "./shared/PrevMovieContainer";
import { PrevMovieContainerRight } from "./shared/PrevMovieContainerRight";

const PreviousMovieTitle = styled.a`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  margin-top: -4px;
  @media (max-width: 1140px) and (min-width: 500px) {
    font-size: 20px;
    line-height: 26px;
    margin-top: -4px;
  }
`;

const PreviousMovieDate = styled.a`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  @media (max-width: 1140px) and (min-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const PreviousVideoContainer = (props) => {
  return (
    <PrevMovieContainer
      key={props.video?.urlIdentifier}
      onClick={() => props.onVideoPress(props.video?.urlIdentifier)}
    >
      <PreviousVideo
        url={props.video?.title}
        thumbnail={props.video?.thumbnailUrl}
      />
      <PrevMovieContainerRight>
        <PreviousMovieTitle>
          {props.video?.title ? props.video?.title : ""}
        </PreviousMovieTitle>
        <PreviousMovieDate>
          {props.video?.publishedDate
            ? getFormattedDate(props.video?.publishedDate)
            : ""}
        </PreviousMovieDate>
      </PrevMovieContainerRight>
    </PrevMovieContainer>
  );
};

export default PreviousVideoContainer;
